import React from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';


function Form(props) {


  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();



    emailjs.sendForm('service_lhrzet3', 'template_qmx2fy5', form.current, 'user_ytBTER1Qr30y30urimAAR')
      .then((result) => {
  
          form.current.reset();
      }, (error) => {
        alert("Error");
        form.current.reset();
      });
  };




  return (
  <>
<div >
	<section className="why-us">

<div className="container mt-5 ">
	      


			<div className="row ">

				<div className="col-sm-6 col-lg-12">
               
					<div className="container shadow-lg p-3 mb-5 bg-white rounded clrt">
                    <form ref={form} onSubmit={sendEmail}>


 
                    <div className="container-fluid">
						
						<h2 className='text-white text-center text-white fontt'> Talk To Your Designer {props.cityname}</h2>
						
	
					</div>

                                       
  <div className="form-row ">
  <div className="form-group col-md-6">
      <label htmlFor="inputname">Name</label>
      <input type="text" className="form-control" name='from_name' id="from_name" required />
    </div>
    <div className="form-group col-md-6">
      <label htmlFor="inputEmail4">Email</label>
      <input type="email" name='from_email' className="form-control" id="from_email"required />
    </div>
   
  </div>
  <div className="form-group">
    <label htmlFor="inputAddress">Phone</label>
    <input type="tel"  pattern="[0-9]{10}" name='from_phone' className="form-control" id="from_phone" placeholder="Phone Number" required />
  </div>
  <div className="form-group">
    <label htmlFor="inputAddress2">Property Name</label>
    <input type="text" className="form-control" name='property_name' id="property_name" placeholder="Property Name" required />
  </div>

  <div className="form-group">
    <label htmlFor="inputAddress2">City</label>
    <input type="text" className="form-control" name='from_city' id="from_city" placeholder="City Name" required />
  </div>

  <div className="form-group">
    <div className="form-check text-center">
      <input className="form-check-input" name='whatsapp' type="checkbox" id="whatsapp" />
      <label className="form-check-label" htmlFor="gridCheck">
        Send Me Updates on WhatsApp
      </label>
    </div>
  </div>

  <div className="form-group">
    <div className="form-check text-center">
     
      <label className="form-check-label" htmlFor="gridCheck">
      <button type="submit" className="btn btn-primary">Get Consultation</button>
      </label>
    </div>
  </div>

  


</form>
					</div>
				</div>

			
			</div>
           
		</div>
        </section>
</div>

  </>
  )
}

export default Form