import React from 'react'
import Form from './Form'
import Formpopup from './Formpopup'


function City(props) {



  return (
   <>
       <div>
           <Form cityname={props.namecity}></Form>
           <Formpopup></Formpopup>
       </div>

   </>
  )
}

export default City