import React from 'react'
import '../App.css';

import { Outlet, Link } from "react-router-dom";
import { auth } from '../firebase';

function Navbar(props) {

  function logout(){
    auth.onAuthStateChanged((user) => {
      auth.signOut();
    });
  }

  return (
    <>
    <nav className="navbar fixed-top bg-light navbar-expand-lg ">
      <a className="navbar-brand mt-2"  >
        <img
          src="favi/favicon-32x32.png"
          width="40"
          height="40"
          className="d-inline-block align-top"
          alt=""
        />
      <span className='fncoll ml-2'>Velvetspace</span> 
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse ml-5" id="navbarNav">
        <ul className="navbar-nav sizenav">
          <li className="nav-item active">
          <Link to="/" className="nav-link fncol" >
              Home <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item">
          <Link to="/spaces" className="nav-link  fncol" >
            Spaces
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/howitwork" className="nav-link fncol"  >
            How it works
            </Link>
          </li>
          <li className="nav-item">
          <Link to="/offering" className="nav-link  fncol"  >
              Offerings</Link>
          </li>
          <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle  fncol"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
        Cities
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
        <Link to="/pune" className="dropdown-item" >Pune</Link>
            <div className="dropdown-divider"></div>
            <Link to="/bangalore" className="dropdown-item" >Bangalore</Link>
          <div className="dropdown-divider"></div>
          <Link to="/kolhapur" className="dropdown-item">Kolhapur</Link>
        </div>
      </li>
        
          <li className="nav-item">
          <Link to="/ourhome" className="nav-link  fncol"  >
           Our Homes</Link>
          </li>

          <li className="nav-item">
          <Link to="/career" className="nav-link  fncol" >
            Career</Link>
          </li>
        
      
          <li className="nav-item mr-2">
          <Link to="/referfriend" className="nav-link  fncol"  >
            Refer A Friend
            </Link>
          </li>

    
          
        

          <li>
            <h6><span className="badge badge-success">{props.name ?  `Welcome ${props.name}` :   <li className="nav-item">
          <Link to="/signin" className="nav-link  fncol"  >
          Signin / Signup</Link>
          </li>}</span></h6>
          </li>


          <li  className="nav-item">
            <h6><span className="">{props.name ?  <button type="button" onClick={logout} className="btn btn-danger" >Logout</button> :   
           " "
         }</span></h6>
          </li>

        

  
        </ul>
        
      </div>
      
    </nav>
      <Outlet />
      </>
  );
}

export default Navbar;

