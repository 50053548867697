import React from 'react'

function Gallary() {

  return (
    <>
    
<div className="container-fluid">
  <div className="jum p-2 m-2">
    <h3>Living Room</h3>
  </div>
  
  <div className="row gallery">
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="living room/l (1).jpg">
        <img className="img-fluid "src="living room/l (1).jpg"  />
      </a>
    </div>

    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="living room/l (5).jpg">
        <img className="img-fluid " src="living room/l (5).jpg" />
      </a>
    </div>


    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="living room/l (2).jpg"> 
        <img className="img-fluid "src="living room/l (2).jpg" />
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4 mt-2">
      <a href="living room/l (3).jpg">
        <img className="img-fluid "src="living room/l (3).jpg" />
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4 mt-2">
      <a href="living room/l (4).jpg">
        <img className="img-fluid "src="living room/l (4).jpg" />
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4 mt-2">
      <a href="living room/l (5).jpg">
        <img className="img-fluid "src="living room/l (5).jpg" />
      </a>
    </div>

   
  
  </div>
</div>

<div className="container-fluid mt-1">
  <div className="jum p-2 m-2">
    <h3>Wardrobes and walk in</h3>
  </div>
  
  <div className="row gallery">
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="wadrobes n walkin/w (1).jpg">
        <img className="img-fluid"src="wadrobes n walkin/w (1).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="wadrobes n walkin/w (2).jpg">
        <img className="img-fluid"src="wadrobes n walkin/w (2).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="wadrobes n walkin/w (3).jpg">
        <img className="img-fluid"src="wadrobes n walkin/w (3).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="wadrobes n walkin/w (4).jpg">
        <img className="img-fluid"src="wadrobes n walkin/w (4).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="wadrobes n walkin/w (5).jpg">
        <img className="img-fluid"src="wadrobes n walkin/w (5).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="wadrobes n walkin/w (6).jpg">
        <img className="img-fluid"src="wadrobes n walkin/w (6).jpg"/>
      </a>
    </div>
    
  </div>
</div>



<div className="container-fluid mt-1">
  <div className="jum p-2 m-2">
    <h3>Study and Home office</h3>
  </div>
  
  <div className="row gallery">
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="study n home office/sthm (1).jpg">
        <img className="img-fluid"src="study n home office/sthm (1).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="study n home office/sthm (1).jpg">
        <img className="img-fluid"src="study n home office/sthm (2).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="study n home office/sthm (1).jpg">
        <img className="img-fluid"src="study n home office/sthm (3).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="study n home office/sthm (1).jpg">
        <img className="img-fluid"src="study n home office/sthm (4).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="study n home office/sthm (1).jpg">
        <img className="img-fluid"src="study n home office/sthm (5).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="study n home office/sthm (1).jpg">
        <img className="img-fluid"src="study n home office/sthm (6).jpg"/>
      </a>
    </div>
   
  </div>
</div>



<div className="container-fluid mt-1">
  <div className="jum p-2 m-2">
    <h3>Kitchen</h3>
  </div>
  
  <div className="row gallery">
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kitchen/k (1).jpg">
        <img className="img-fluid"src="kitchen/k (1).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kitchen/k (1).jpg">
        <img className="img-fluid"src="kitchen/k (2).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kitchen/k (1).jpg">
        <img className="img-fluid"src="kitchen/k (3).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kitchen/k (1).jpg">
        <img className="img-fluid"src="kitchen/k (4).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kitchen/k (1).jpg">
        <img className="img-fluid"src="kitchen/k (5).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kitchen/k (1).jpg">
        <img className="img-fluid"src="kitchen/k (1).jpeg"/>
      </a>
    </div>
  </div>
</div>



<div className="container-fluid mt-1">
  <div className="jum p-2 m-2">
    <h3>Bedroom</h3>
  </div>
  
  <div className="row gallery">
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="bedroom/b (1).jpg">
        <img className="img-fluid"src="bedroom/b (1).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="bedroom/b (1).jpg">
        <img className="img-fluid"src="bedroom/b (2).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="bedroom/b (1).jpg">
        <img className="img-fluid"src="bedroom/b (3).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="bedroom/b (1).jpg">
        <img className="img-fluid"src="bedroom/b (4).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="bedroom/b (1).jpg">
        <img className="img-fluid"src="bedroom/b (5).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="bedroom/b (1).jpg">
        <img className="img-fluid"src="bedroom/b (1).jpeg"/>
      </a>
    </div>
   
    
  </div>
</div>


<div className="container-fluid mt-1">
  <div className="jum p-2 m-2">
    <h3>Kids room</h3>
  </div>
  
  <div className="row gallery">
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kids bedroom/kd (1).jpg">
        <img className="img-fluid"src="kids bedroom/kd (1).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kids bedroom/kd (1).jpg">
        <img className="img-fluid"src="kids bedroom/kd (2).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kids bedroom/kd (1).jpg">
        <img className="img-fluid"src="kids bedroom/kd (3).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kids bedroom/kd (1).jpg">
        <img className="img-fluid"src="kids bedroom/kd (4).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kids bedroom/kd (1).jpg">
        <img className="img-fluid"src="kids bedroom/kd (5).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="kids bedroom/kd (1).jpg">
        <img className="img-fluid"src="kids bedroom/kd (6).jpg"/>
      </a>
    </div>
  </div>
</div>




<div className="container-fluid mt-2">
  <div className="jum p-2 m-2">
    <h3>Outdoor Living</h3>
  </div>
  
  <div className="row gallery">
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="outdoor living/o (1).jpg">
        <img className="img-fluid"src="outdoor living/o (1).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="outdoor living/o (1).jpg">
        <img className="img-fluid"src="outdoor living/o (2).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="outdoor living/o (1).jpg">
        <img className="img-fluid"src="outdoor living/o (3).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="outdoor living/o (1).jpg">
        <img className="img-fluid"src="outdoor living/o (4).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="outdoor living/o (1).jpg">
        <img className="img-fluid"src="outdoor living/o (5).jpg"/>
      </a>
    </div>
    <div className="col-sm-6 col-md-4 col-lg-4">
      <a href="outdoor living/o (1).jpg">
        <img className="img-fluid"src="outdoor living/o (6).jpg"/>
      </a>
    </div>
  </div>
</div>


</>
  )
}

export default Gallary