import React from 'react'

function OurService() {
  return (
    <>
    	<section className="why-us ">
		<div className="container">
	        <div className="row">
				<div className="col-md-8 offset-md-2">
					<h2 className="mt-5 text-center">Our Services</h2>
					<p className="mb-5 text-center"></p>
				</div>
			</div>
            </div>
            </section>
    <section className="our-skills">    
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-sm-2 col-md-4">
                <div className="box-shadow bhg">
                    <div className="icon">
                    <img className='gn' src="servicesImgaes\room.png"/ >
                    </div>
                    <h4>
Living/Dining Room</h4>
                   
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="box-shadow bhg">
                    <div className="icon">
                    <img className='gn' src="servicesImgaes\bedroom.png"/ >
                    </div>
                    <h4>Bedroom</h4>
                   
                </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="box-shadow bhg">
                    <div className="icon">
                    <img className='gn' src="servicesImgaes\kitchen.png"/ >
                    </div>
                    <h4>
Kitchen</h4>
                   
                </div>  
            </div>
            <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="box-shadow bhg">
                    <div className="icon">
                    <img className='gn' src="servicesImgaes\wardrobe.png"/ >
                    </div>
                    <h4>
Innovative Storage</h4>
                    
                </div>
            </div>
           
            
        </div>
    </div>
</section>  
    </>
  )
}

export default OurService