import React from 'react'
import Gallary from './Gallary'

function Spaces() {
  return (
    <div className='ma container-fluid text-center text-danger'>
      <Gallary></Gallary>
    </div>
  )
}

export default Spaces