import React from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';


function Formpopup() {

    const close= function () {
 
         document.getElementById("exampleModal").setAttribute("aria-hidden", "true"); 
         document.getElementById("exampleModal").setAttribute("class", "modal fade"); 
         document.getElementById("exampleModal").style.display="none";
         document.getElementById("exampleModal").style.padding="0px"; 
         document.getElementById("exampleModal").removeAttribute("aria-modal");
         document.getElementById("exampleModal").removeAttribute("role");

     }

     const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();



    emailjs.sendForm('service_lhrzet3', 'template_qmx2fy5', form.current, 'user_ytBTER1Qr30y30urimAAR')
      .then((result) => {
  
          form.current.reset();
      }, (error) => {
        alert("Error");
        form.current.reset();
      });
  };


    

  return (
    <>
    



<div className="modal fade " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog clrt">
    <div className="modal-content">
      <div className="modal-header clrt">
        <h5 className="modal-title text-center " id="exampleModalLabel">Talk To Your Designer </h5>
        <button type="button" onClick={close} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body clrt">
        <form ref={form} onSubmit={sendEmail}>
        <div className="form-group">
            <label htmlFor="inputEmail4">Name</label>
            <input type="text" name='from_name' className="form-control" placeholder="Name" id="from_name"required />
          </div>
        <div className="form-group">
            <label htmlFor="inputEmail4">Email</label>
            <input type="email" name='from_email' className="form-control" placeholder="Email" id="from_email"required />
          </div>
          <div className="form-group ">
    <label htmlFor="inputAddress">Phone</label>
    <input type="tel"  pattern="[0-9]{10}" name='from_phone' className="form-control" id="from_phone" placeholder="Phone Number" required />
  </div>

  <button type="submit" className="btn btn-primary">Get Consultation</button>
        </form>
      </div>
     
    </div>
  </div>
</div>

  


    </>
  )
}

export default Formpopup