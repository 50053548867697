import React from 'react'
import { Outlet, Link } from "react-router-dom";

function Carousel() {
  return (
    <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src="pp (3).jpg" className=" w-100" height='600px' alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h1 className='sizeheader'>A home so good, you’ll love the way you Live.</h1>
        <button type="button" className="btn btn-danger rounded-pill sizebtn ">
        <Link to="/talk" className='text-white'>
        Book design consultation 
            </Link>
          </button>
        
      </div>
    </div>
    <div className="carousel-item">
      <img src="pp (2).jpg" className="w-100" height='600px' alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h1 className='sizeheader'>Smart and efficient home interiors with superior quality materials 
					</h1>
          <button type="button" className="btn btn-danger rounded-pill sizebtn ">
        <Link to="/talk" className='text-white'>
        Book design consultation 
            </Link>
          </button>
       
      </div>
    </div>
    <div className="carousel-item">
      <img src="pp (1).jpg" className=" w-100" height='600px' alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h1 className='sizeheader'>Let's shape the future of home interiors, together</h1>
        <button type="button" className="btn btn-danger rounded-pill  sizebtn ">
        <Link to="/talk" className='text-white'>
        APPLY TODAY
            </Link>
          </button>
        
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-target="#carouselExampleCaptions" data-slide="prev">
  <span className='nextb rounded-circle p-2'>    <span className="carousel-control-prev-icon " aria-hidden="true"></span></span>
    <span className="sr-only">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-target="#carouselExampleCaptions" data-slide="next">
  <span className='nextb rounded-circle p-2'>  <span className="carousel-control-next-icon " aria-hidden="true"></span></span>
    <span className="sr-only">Next</span>
  </button>
  <Outlet />
</div>
  )
}

export default Carousel