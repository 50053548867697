import React from 'react'
import City from './City'

function Pune() {
  return (
    <>
    <section className="why-us maaa">
    
          <div><City namecity="In Pune"></City></div>
        </section>
    </>
    
  )
}

export default Pune