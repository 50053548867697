import React from 'react'

function Pageimage() {
  return (
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
        <div className="carousel-item active">
        <div className="carousel-caption d-none d-md-block">
        <h1 className='c'>A trouble free <br/>Home Interior Journey</h1>
        
      </div>
            <img src="bg22.jpg" className="d-block w-100" alt="..."/>
        </div>
    </div>
    </div>
  )
}

export default Pageimage