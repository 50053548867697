import React from 'react'
import Tablecontrol from './Tablecontrol'

function Career() {
  return (
    <div className='ma text-center text-danger container'>

      <div className='m-3'>
       <h2 className='fontt'>Work at Velvetspace</h2> 
      </div>

<table className="table table-hover">
  <thead className='clrt'>
    <tr>
      <th scope="col">Title</th>
      <th scope="col">Location</th>
      <th scope="col">Apply Here</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Business Development Executive</td>
      <td>Bangalore (On-Site)</td>
      <td><a href='https://www.linkedin.com/jobs/view/3223190818/?refId=TTZLa0OqieDIRAcVHIxTvw%3D%3D&trackingId=DJV5U%2BnO6gZHk7e76NViyg%3D%3D'><button type="button"   className="btn btn-success">Apply</button></a></td>
    </tr>

  </tbody>
</table>

  </div>
  )
}

export default Career