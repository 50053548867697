import React from 'react'
import City from './City'

function Kolhapur() {
  return (
    <section className="why-us maaa">
    
         <div><City namecity="In Kolhapur"></City></div>
       </section>
  )
}

export default Kolhapur