import React from 'react'
import Pageimage from './Pageimage'

function Offering() {
  return (
      <> <Pageimage></Pageimage>
    <div className='container-fluid'>
       

    <section className="why-us">
		<div className="container">
    <div className="row">
				<div className="col-md-8 offset-md-2">
					<h2 className="mt-5 text-center">One- stop shop for all effects home innards</h2>
					<p className="mb-5 text-center">Whether it’s a magazine- suchlike dream kitchen or your entire home, enjoy end- to- end <br/> results from design to installation.</p>
				</div>
			</div>
      </div>
      </section>

    <div className="row d-flex justify-content-lg-center">
        
        <div className="col-sm-6 col-lg-5 ">
            <div className="card shadow p-3 mb-5 bg-white rounded border-0">
            <img src="cozy.jpg" className="card-img-top" height={500} alt="..."/>
            <div className="card-body">
                <strong className="card-title">Cozy</strong>
                <p className="card-text text-muted">
								Get the complete home interior experience with award-winning designers, service partners, and the best brands.
							</p>
               
            </div>
            </div>
        </div>
        <div className="col-sm-6 col-lg-5 ">
            <div className="card shadow p-3 mb-5 bg-white rounded border-0">
            <img src="elite.jpg" className="card-img-top" height={500} alt="..."/ >
            <div className="card-body">
                <strong className="card-title">Elite</strong>
                <p className="card-text text-muted">
					High quality, budget-friendly modular solutions like kitchens, wardrobes, and more in your favourite colours, and designs.
							</p>
            </div>
            </div>
        </div>
        </div>
       
    </div>
    </>
  )
}

export default Offering