import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDLQRbH8xdIC6QQgaSkD6Dh6xEl3CcL99k",
  authDomain: "velvetspace-9464b.firebaseapp.com",
  projectId: "velvetspace-9464b",
  storageBucket: "velvetspace-9464b.appspot.com",
  messagingSenderId: "38060383748",
  appId: "1:38060383748:web:d8b605388665829472df51",
  databaseURL: "https://velvetspace-9464b-default-rtdb.firebaseio.com"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();

export const storage = getStorage(app);
export const db = getFirestore(app);

export { app, auth };

export default db;
