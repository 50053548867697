import './App.css';
import './arrow.js';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Offering from './components/Offering';
import Pune from './components/Pune';
import Bangalore from './components/Bangalore';
import Kolhapur from './components/Kolhapur';
import Spaces from './components/Spaces';
import Career from './components/Career';
import Ourhome from './components/Ourhome';
import Referfriend from './components/Referfriend';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Howitworks from './components/Howitworks';
import Talk from './components/Talk';
import Formpopup from './components/Formpopup';
import { useEffect, useState } from "react";
import React from "react";
import { auth } from "./firebase";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Userhome from './components/Userhome';
import Admin from './components/Admin';

function App() {

  window.onload = function () {                                                

   document.getElementById("exampleModal").setAttribute("role", "dialog"); 
   document.getElementById("exampleModal").setAttribute("class", "modal fade show"); 
   document.getElementById("exampleModal").setAttribute("aria-modal", "true");
    document.getElementById("exampleModal").style.display="block";
    document.getElementById("exampleModal").style.padding="17px"; 
    document.getElementById("exampleModal").removeAttribute("aria-hidden"); 
             
  }; 

  const [userName, setUserName] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
   
      if (user) {

        setUserName(user.displayName);
      } else setUserName("");
    });
  }, []);

        ///<Route path="/UserHome" element={<Userhome username={userName}></Userhome>} />    
         /// <Route path="/Admin" element={<Admin></Admin>} />


  return (
    <>
     

     <BrowserRouter>
     <Navbar name={userName}  setuser={setUserName}></Navbar>
     <Formpopup></Formpopup>
      <Routes>
       
          <Route path="/" element={<Home></Home>} />
          <Route path="/howitwork" element={<Howitworks></Howitworks>} />
          <Route path="/offering" element={<Offering></Offering>} />
          <Route path="/spaces" element={<Spaces></Spaces>} />
          <Route path="/ourhome" element={<Ourhome  ></Ourhome>} />
          <Route path="/career" element={<Career></Career>} />
          <Route path="/referfriend" element={<Referfriend></Referfriend>} />
          <Route path="/signin" element={<Login ></Login >} />
          <Route path="/signup" element={<Signup></Signup>} />
          <Route path="/pune" element={<Pune></Pune>} />
          <Route path="/kolhapur" element={<Kolhapur></Kolhapur>} />
          <Route path="/bangalore" element={<Bangalore></Bangalore>} />
          <Route path="/talk" element={<Talk></Talk>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
    </>
  );
}

export default App;
