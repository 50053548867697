import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Referfriend() {



  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();



    emailjs.sendForm('service_lhrzet3', 'template_z7skua4', form.current, 'user_ytBTER1Qr30y30urimAAR')
      .then((result) => {
          alert("Success ! Thank you.");
          form.current.reset();

          
      }, (error) => {
        alert("Error");
        form.current.reset();
      });
  };



  return (
    <>
    <div className="container-fluid maa">
    <div className="row mt-30 mb-30">
      <div className="col-sm-12 col-md-3">
        <div className="share-boxes">
          <img src="https://i.ibb.co/PtYrLNy/img1.png" alt="img1" border="0"/>
          <p>Share with your friends</p>
        </div>
      </div>
      <div className="col"></div>
      <div className="col-sm-12 col-md-3">
        <div className="share-boxes">
          <img src="https://i.ibb.co/P5TdfkT/img2.png" alt="img2" border="0"/>
          <p>Give her to Discount</p>
          <img src="https://i.ibb.co/Sr5F70S/dotted-arrow1.png" alt="dotted-arrow1" className="dotted-line"/>
          <img src="https://i.ibb.co/Fqs2KxB/dotted-arrow2.png" alt="dotted-arrow2" className="dotted-line2"/>
        </div>
      </div>
      <div className="col"></div>
      <div className="col-sm-12 col-md-3">
        <div className="share-boxes">
          <img src="https://i.ibb.co/StC3RWk/img3.png" alt="img3" border="0"/>
          <p>Get 5% for every Referal</p>
        </div>
      </div>
    </div>
    <div className="row">
   
        <div className="col-md-6 offset-md-3">
            <div className="mb-3 text-center text-danger">
              
            </div>
            <form name="ReferFriend" ref={form} onSubmit={sendEmail} className="shadow p-4">                  
                <div className="mb-3">
                    <label htmlFor="username">Your Email</label>
                    <input type="email" className="form-control" name="from_email" id='from_email' placeholder="Email"/>
                </div>

                <div className="mb-3">
                    <label htmlFor="username">Friends Phone Number</label>
                    <input type="tel"  pattern="[0-9]{10}" className="form-control" name="refer_phone" id='refer_phone'  placeholder="Phone Number" required/>
                </div>

                <div className="mb-3">
                    <label htmlFor="username">Friends Email</label>
                    <input type="email" className="form-control" name="refer_email" id='refer_email'  placeholder="Email" required/>
                </div>

                <div className="mb-3">
                <label className="container-checkbox text-center">Accept Terms and Conditions</label>
                <input type="checkbox" name='TandC' className="form-control"   required/>
                </div>

         
                <div className="mb-3 text-center">
                    <button type="submit" className="btn btn-primary">REFER & EARN</button>
                </div>

                <small>
                <hr/>
               1.  A successful referral is defined as a referred customer who places an order with HomeLane and pays 5% of total order value.<br></br> <hr/>
     2. You will be eligible for either Rs.10,000 referral bonus per successful referral.<br></br> <hr/>
    3. Your payment will be processed after any referred person makes a payment of 5% of the total order value.<br></br> <hr/>
     4. Your payment will be processed directly to the account linked with your profile.<br></br> <hr/>
     5. Minimum value of the referred order should be Rs. 1.5 Lacs.<br></br> <hr/>
     6. Customers already existing in the HomeLane system will not be considered for the referral scheme.<br></br> <hr/>
     7. Referral bonus cannot be combined or adjusted against any existing payment.<br></br> <hr/>
     8. Velvetspace reserves the right to end any or all the offers at its sole discretion without any prior notice.<br></br> <hr/>
     9. Velvetspace reserves the right to deny any suspected illegitimate case.<br></br>
                </small>

                <hr/>

                  
            </form>

        </div>

    </div>
    
  </div>
  </>
  )
}

export default Referfriend