import React from 'react'
import Pageimage from './Pageimage'

function Howitworks() {
  return (
<>


<Pageimage></Pageimage>
<section className="why-us mt-5">

<section className="why-us">
		<div className="container">
    <div className="row">
				<div className="col-md-8 offset-md-2">
					<h2 className="mt-5 text-center">How it Works</h2>
					<p className="mb-5 text-center">We provide you with comfort flow of work.</p>
				</div>
			</div>
      </div>
      </section>

	<div className="container">

	    <div className="d-flex justify-content-center">

			<div className="col-sm-6 col-lg-3">
            <div className="profile-card text-center shadow bg-light p-4 my-5 rounded-3">
                    <div className="profile-photo shadow">
                        <img src="team.png" alt="profile Photo" className="img-fluid"/>
                    </div>
                   
                    <p className="text-secondary">Meet Expert at Your Place/Comfort</p>
                   
                </div>
		    </div>
            

            <div className='d-flex align-items-center'> 
            <div>
                <img src="right-chevron.png" alt="Arrow" className='arrow'/>
            </div>
            </div>

            <div className="col-sm-6 col-lg-3">
            <div className="profile-card text-center shadow bg-light p-4 my-5 rounded-3">
                    <div className="profile-photo shadow">
                        <img src="vr-glasses.png" alt="profile Photo" className="img-fluid"/>
                    </div>
                   
                    <p className="text-secondary">Experience 5D Visuals of Your Home</p>
                   
                </div>
		    </div>


            <div className='d-flex align-items-center'> 
            <div>
                <img src="right-chevron.png" alt="Arrow" className='arrow'/>
            </div>
            </div>

            <div className="col-sm-6 col-lg-3">
            <div className="profile-card text-center shadow bg-light p-4 my-5 rounded-3">
                    <div className="profile-photo shadow">
                        <img src="interior-design.png" alt="profile Photo" className="img-fluid"/>
                    </div>
                    
                    <p className="text-secondary">Installation</p><br/>
                    
                </div>
		    </div>

            <div className='d-flex align-items-center'> 
            <div>
                <img src="right-chevron.png" alt="Arrow" className='arrow'/>
            </div>
            </div>


            <div className="col-sm-6 col-lg-3">
            <div className="profile-card text-center shadow bg-light p-4 my-5 rounded-3">
                    <div className="profile-photo shadow">
                        <img src="walking.png" alt="profile Photo" className="img-fluid"/>
                    </div>
                    
                    <p className="text-secondary">Move in</p><br/>
                   
                </div>
		    </div>

        </div>
    </div>
</section>
        

</>
        
  )
}

export default Howitworks