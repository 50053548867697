import React from 'react'

function Whyus() {
  return (
    <div className='text-center'>
	<section className="why-us">
		<div className="container-fluid ">
	        <div className="row">
				<div className="col-md-8 offset-md-2">
					<h2 className="mt-5 text-center">Why Choose Us</h2>
					<p className="mb-5 text-center"></p>
				</div>
			</div>


		

		<div className="row d-flex justify-content-center">
			<div className="col-sm-6 col-sm-6 col-lg-3 shadow-lg p-3 mb-5 bg-white rounded m-2">
				<div className="img-box">
				<span className="badge badge-success">01</span>
						<h4><a >Best Price Guarantee<br></br><br></br></a></h4>
					<img src="whyus/best-price.png"   alt=""/>
				</div>
			</div>

			<div className="col-sm-6 col-lg-3 shadow-lg p-3 mb-5 bg-white rounded m-2">
				<div className="img-box">
				<span className="badge badge-success">02</span>
						<h4><a >30 Days Move in Guarantee<br></br><br></br> </a></h4>
					<img src="whyus/guarantee.png" alt=""/>
				</div>
			</div>

			<div className="col-sm-6 col-lg-3 shadow-lg p-3 mb-5 bg-white rounded m-2">
				<div className="img-box">
				<span className="badge badge-success">03</span>
						<h4><a >12 Years Warranty<br></br><br></br></a></h4>
					<img src="whyus/shield.png" alt=""/>
				</div>
			</div>

			<div className="col-sm-6 col-lg-3 shadow-lg p-3 mb-5 bg-white rounded m-2">
				<div className="img-box">
				<span className="badge badge-success">04</span>
						<h4><a >We Are Virtual Reality Visualization<br></br><br></br></a></h4>
					<img src="whyus/dashboard.png"   alt=""/>
				</div>
			</div>

			<div className="col-sm-6 col-lg-3 shadow-lg p-3 mb-5 bg-white rounded m-2">
				<div className="img-box">
				<span className="badge badge-success">05</span>
						<h4><a>Quality Assurance Certification<br></br><br></br></a></h4>
					<img src="whyus/quality.png"   alt=""/>
				</div>
			</div>

			<div className="col-sm-6 col-lg-3 shadow-lg p-3 mb-5 bg-white rounded m-2">
				<div className="img-box">
				<span className="badge badge-success">06</span>
						<h4><a >After Sell Support<br></br><br></br></a></h4>
					<img src="whyus/social-care.png"   alt=""/>
				</div>
			</div>

			
		
	</div>

	<div className='d-flex justify-content-center '>
<div className="col-sm-6 col-lg-4 shadow-lg p-3 mb-5 bg-white rounded m-2">
				<div className="img-box">
				<span className="badge badge-success">07</span>

						<h4><a >No Hidden Cost<br></br><br></br></a></h4>
					<img src="whyus/hidden.png"  alt=""/>
				</div>
			</div>

</div>



		</div>
	</section>



    



	</div>
  )
}

export default Whyus