import React from 'react'

function Blogs() {
  return (
    <>
    <div>
        <div className="container-fluid text-center bg-grey">
        <section className="why-us">
		<div className="container">
    <div className="row">
				<div className="col-md-8 offset-md-2">
					<h2 className="mt-5 text-center text-dark">What We Have Created</h2>
					<p className="mb-5 text-center text-muted">Lets Here From Our Customer.</p>
				</div>
			</div>
      </div>
      </section>
 
  <div className="row text-center">
   

    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (13).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Pune</strong></p>
        <p>"Great work! Very professional and lived upto the expectations! 💯🥃"<br></br>
          - Sameet Pathan
        </p>
      </div>
    </div>

    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (14).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Pune</strong></p>
        <p>"Initial contact with Velvetspace, I am happy with sales person, through purchase, engineering to delivery, my experience was very favorable.
        <br></br>  - Amin Mutawlli" </p>

      </div>
    </div>

    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (15).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Bengaluru</strong></p>
        <p>"It has been a pleasure to take service from such a passionate, dedicated team providing A class service on time. Very happy to get out interiors done from velvet space. I recommend to all.
       " <br></br> - Akshay Mahale</p>

      </div>
    </div>




  </div>
</div>
    </div>

    <div>
        <div className="container-fluid text-center bg-grey">

  <div className="row text-center">
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (10).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Kolhapur</strong></p>
      </div>
    </div>
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (11).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Pune</strong></p>
      </div>
    </div>
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (12).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Kolhapur</strong></p>
      </div>
    </div>
  </div>
</div>
    </div>




    <div>
        <div className="container-fluid text-center bg-grey">

  <div className="row text-center">
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (4).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Bengaluru</strong></p>
      </div>
    </div>
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (5).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Kolhapur</strong></p>
      </div>
    </div>
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (6).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Bengaluru</strong></p>
      </div>
    </div>
  </div>
</div>
    </div>




    <div>
        <div className="container-fluid text-center bg-grey">

  <div className="row text-center">
  <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded ">
      <div className="thumbnail">
        <img src="our homes/ohc (3).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Bengaluru</strong></p>
      </div>
    </div>
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (8).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Pune</strong></p>
      </div>
    </div>
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded">
      <div className="thumbnail">
        <img src="our homes/ohc (9).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Bengaluru</strong></p>
      </div>
    </div>
  </div>
</div>
    </div>


    <div>
        <div className="container-fluid text-center bg-grey">

  <div className="row text-center">

  <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded ">
      <div className="thumbnail">
        <img src="our homes/ohc (1).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Bengaluru</strong></p>
      </div>
    </div>
    <div className="col-sm-4 shadow p-3 mb-5 bg-white rounded ">
      <div className="thumbnail">
        <img src="our homes/ohc (2).jpg"  width="400" height="300"/>
        <p className='m-1'><strong className='text-danger'>Bengaluru</strong></p>
      </div>
    </div>
  

  </div>

</div>
    </div>



    


    </>
  )
}

export default Blogs