import React from 'react'
import { Outlet, Link } from "react-router-dom";

function Footer() {
  return (
	<div>
		<footer>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div>
                                <h4 >Velvetspace</h4>
                                <p><span className='text-dark bold'>Head  Office:</span>448 HBR Layout 4TH Block, Service Road, Bangalore - 560043 , India</p>
                                <p><span className='text-dark'>Other Office:</span>Punya Pravah Near Wins Hospital, Kolhapur ,India</p>
                                <p><span className='text-dark'>Other Office:</span>Ellora Residency Dehu Road , Pune ,India</p>
                                <p><span className='text-dark'>Email:</span>admin@velvetspace.in</p>
                            </div>

                            <div>
                                <h4>Customer Care</h4>
                                <p><span className='text-dark'>Phone:</span> +91 88675-72229</p>
                                <p ><span className='text-dark'>Phone:</span> +91 88675-32229</p>
                            </div>

                        </div>

                        <div className="col-lg-2 col-sm-6 footer-menus">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="fas fa-check"></i> <a href="#">Book Now</a></li>
                                <li><i className="fas fa-check"></i> <a href="#">How It Works</a></li>
                                <li><i className="fas fa-check"></i> <a href="#">Career</a></li>
                                <li><i className="fas fa-check"></i> <a href="#">Signin</a></li>
                                <li><i className="fas fa-check"></i> <a href="#">Signup</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-sm-6 footer-menus">
                            <h4>Our Services</h4>
                            <ul>


                                <li><a >Living/Dining Room</a></li>
                                <li><a >Bedroom</a></li>
                                <li><a >Kitchen</a></li>
                                <li><a >
Innovative Storage</a></li>
                                
                            </ul>
                        </div>

                        <div className="col-lg-4 col-sm-6 newsletter">
                            <h4>About</h4>
                            <p>At Velvetspace , we believe that interior design is more than great functionality and beautiful aesthetics. We aim to make your home interiors a reflection of your personality</p>
                            <button className='btn btn-danger rounded-pill'> <Link to="/talk" className='text-white'>
        Book design consultation 
            </Link></button>

                            <div className="mt-3">
                                
                                <a href="https://www.facebook.com/The-Velvet-Space-100590688450158" className='m-2'><img src="facebook.png" height={20} alt="" srcSet="" /></a>
                                <a href="https://www.instagram.com/the_velvet_space/"  className='m-2'><img src="instagram.png" height={20} alt="" srcSet="" /></a>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-center mb-2">&copy; Copyright <strong>Velvetspace</strong>. All Rights Reserved</p>
                            <p className="text-center mb-0">Created by <a href="">velvetspace</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

		<Outlet />
	</div>
  )
}

export default Footer
