import React from 'react'
import Blogs from './Blogs'

function Ourhome() {
  return (
    <div className='ma text-center  container-fluid'>

     <Blogs></Blogs>
    </div>
  )
}

export default Ourhome