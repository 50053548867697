import React from 'react'
import Form from './Form'

function Talk() {
  return (
    <div className='ma'>
        <Form></Form>
    </div>
  )
}

export default Talk